/*
 * causes.less
 * -----------------------------------------------
*/

/* -------- Recent Causes ---------- */
.causes .progress-item .progress-bar .percent {
  background-color: fade(@white-base, 90%);
  border: 2px solid;
  border-radius: 50%;
  color: @black-333;
  font-size: 10px;
  line-height: 8px;
  padding: 12px 6px;
  position: absolute;
  right: -10px;
  text-align: center;
  top: -10px;
}
.causes .progress-item .progress {
  background-color: lighten(@black, 93.8%);
  border-bottom: 1px solid @gray-lightgray;
  box-shadow: none;
  height: 15px;
}

/*
 * 3.6  -> Causes
 * -----------------------------------------------
*/
.causes .thumb {
  position: relative;
}
.causes .thumb .donate-btn {
  opacity: 0;
  position: absolute;
  right: -50px;
  top: 0px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.causes:hover .thumb .donate-btn {
  opacity: 1;
  right: -15px;
}
.causes .thumb .donate-piechart {
  bottom: -40px;
  right: 15px;
  position: absolute;
}
.causes .thumb .donate-piechart .piechart-block {
  border-radius: 50%;
}
.causes .thumb .donate-piechart .piechart canvas {
  max-height: 80px;
}
.causes .thumb .donate-piechart .piechart-block .piechart {
  min-height: 80px;
  min-width: 80px;
}
.causes .thumb .donate-piechart .piechart-block .percent::after {
  font-size: 12px;
}
.causes .thumb .donate-piechart .piechart-block .percent {
  font-size: 18px;
  line-height: 77px;
}

/*
 * 3.29  -> Pie Charts
 * -----------------------------------------------
*/
.piechart {
  position: relative;
  margin: 0 auto;
  text-align: center;
}
.piechart .percent {
  font-size: 18px;
  z-index: 100;
  line-height: 60px;
}
.piechart .percent:after {
  content: "%";
  font-size: 18px;
}
.piechart canvas {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}