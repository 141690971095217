/*
 * gallery-isotope.less
 * -----------------------------------------------
*/

/* -------- Portfolio Isotop Filter ---------- */
.portfolio-filter {
	margin-bottom: 30px;
	a {
		border-left: 3px solid transparent;
		background-color: @white-f8;
		display: inline-block;
		float: none;
		font-size: 16px;
		font-weight: 400;
		line-height: normal;
		margin-right: 5px;
		outline: medium none;
		padding: 5px 15px;
		.transition(all 100ms ease-in-out 0s);
		&.active ,
		&:hover {
			color: @white-base;
		}
		@media only screen and (max-width: 479px) {
			margin-bottom: 10px;
		}
	}
}

/* -------- Portfolio Isotop Gallery ---------- */
.gallery-isotope {
	/*gutter*/
	&.gutter .gallery-item {
	    padding-right: 5px;
	    padding-bottom: 5px;
	}
	&.gutter-small .gallery-item {
	    padding-right: 2px;
	    padding-bottom: 2px;
	}
	&.gutter-0 .gallery-item {
	    padding-right: 0;
	    padding-bottom: 0;
	}
	&.gutter-5 .gallery-item {
	    padding-right: 5px;
	    padding-bottom: 5px;
	}
	&.gutter-10 .gallery-item {
	    padding-right: 10px;
	    padding-bottom: 10px;
	}
	&.gutter-20 .gallery-item {
	    padding-right: 20px;
	    padding-bottom: 20px;
	}
	&.gutter-30 .gallery-item {
	    padding-right: 30px;
	    padding-bottom: 30px;
	}
	&.gutter-40 .gallery-item {
	    padding-right: 40px;
	    padding-bottom: 40px;
	}
	&.gutter-50 .gallery-item {
	    padding-right: 50px;
	    padding-bottom: 50px;
	}
	&.gutter-60 .gallery-item {
	    padding-right: 60px;
	    padding-bottom: 60px;
	}

	/* item sizer */
	.gallery-item-sizer {
	    padding-right: 0 !important;
	    padding-bottom: 0 !important;
		width: 25%;
		&.wide {
			width: 50%;
		}
	}

	/* by default 4 grids */
	.gallery-item {
		width: 25%;
		&.wide {
			width: 50%;
		}
	}

	/*portfolio 2 grids*/
	&.grid-1 .gallery-item {
	    width: 100%;
		&.wide {
			width: 100%;
		}
	}

	/*portfolio 2 grids*/
	&.grid-2 .gallery-item {
	    width: 50%;
		&.wide {
			width: 100%;
		}
	}

	/*portfolio 3 grids*/
	&.grid-3 .gallery-item {
	    width: 33.333%;
		&.wide {
			width: 66.6667%;
		}
	}

	/*portfolio 4 grids*/
	&.grid-4 .gallery-item {
	    width: 25%;
		&.wide {
			width: 50%;
		}
	}

	/*portfolio 5 grids*/
	&.grid-5 .gallery-item {
	    width: 20%;
		&.wide {
			width: 40%;
		}
	}

	/*portfolio 6 grids*/
	&.grid-6 .gallery-item {
	    width: 16.666666667%;
		&.wide {
			width: 33.333%;
		}
	}

	/*portfolio 7 grids*/
	&.grid-7 .gallery-item {
	    width: 14.2857143%;
		&.wide {
			width: 28.5714286%;
		}
	}

	/*portfolio 8 grids*/
	&.grid-8 .gallery-item {
	    width: 12.5%;
		&.wide {
			width: 25%;
		}
	}

	/*portfolio 9 grids*/
	&.grid-9 .gallery-item {
	    width: 11.11%;
		&.wide {
			width: 22.22%;
		}
	}

	/*portfolio 10 grids*/
	&.grid-10 .gallery-item {
	    width: 10%;
		&.wide {
			width: 20%;
		}
	}
}

/* -------- Portfolio Isotop Item Media Query ---------- */
.gallery-isotope {
	.gallery-item {
		@media only screen and (max-width: 991px) {
			width: 33.333% !important;
			&.wide {
				width: 66.6667% !important;
			}
		}
		@media only screen and (max-width: 767px) {
			width: 50% !important;
			&.wide {
				width: 100% !important;
			}
		}
		@media only screen and (max-width: 479px) {
			width: 100% !important;
			&.wide {
				width: 100% !important;
			}
		}
	}
}

/* -------- Portfolio Isotop Overlay Effect ---------- */
.gallery-isotope {
	.gallery-item {
		overflow: hidden;
		.thumb {
			position: relative;
			overflow: hidden;
		}

		.hover-link {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			vertical-align: middle;
			width: 100%;
			height: 100%;
			background: none!important;
			color: transparent!important;
			z-index: 11;
		}

		.overlay-shade {
			position: absolute;
			display: inline-block;
			vertical-align: middle;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 12;
			background-color: #333;
			opacity: 0;
			filter: alpha(opacity=0);
			.transition(opacity .3s ease-in-out);
		}

		&:hover .overlay-shade {
			opacity: 0.9;
			filter: alpha(opacity=90);
		}

		.icons-holder {
			position: absolute;
			top: 0%;
			left: 50%;
			display: inline-block;
			width: auto;
			height: 36px;
			vertical-align: middle;
			z-index: 13;
			.transition(all .3s ease-in-out);

			.icons-holder-inner {
				position: relative;
				display: inline-block;
				vertical-align: middle;
				margin: -18px 0 0 -50%;

				a {
					opacity: 0;
					filter: alpha(opacity=0);
					.transition(all .3s ease-in-out);
				}
			}
		}

		&:hover .icons-holder {
			top: 50%;
			.icons-holder-inner {
				a {
					opacity: 1;
					filter: alpha(opacity=1);
				}
			}
		}

		.portfolio-description {
		 	padding: 10px 15px;
		 	background-color: @white-f8;

		 	.title {
			 	margin: 0;
			 	a {
					color: @headings-color;
				}
			}
		}

		.text-holder {
		 	box-sizing: border-box;
			display: block;
			height: 100%;
			left: 0;
			opacity: 0;
			overflow: hidden;
			padding: 25px 30px 60px;
			position: absolute;
			top: 0;
			.transition(opacity 0.3s ease-in-out 0s);
			width: 100%;
			z-index: 13;

		 	.title {
		 		color: #fff;
			}
		}

		&:hover .text-holder {
			opacity: 1;
		}
	}
	//flex slider gallery control nav
	.flex-control-nav {
		z-index: 111;
	}
}
