/*------------------------------------------------------------------
[Master Stylesheet]

Project:        MartialArts
Version:        1.0
Last change:    07.24.2017
Primary use:    Karate Academy & Martial Arts Trainings HTML5 Template
Author:         ThemeMascot
URL:            http://themeforest.net/user/ThemeMascot
-------------------------------------------------------------------*/
 
// Table of contents
@import "less-martial-art/table-of-content.less";


// Import bootstrap variables and mixins for reference
@import (reference) "less-bootstrap/variables.less";
@import (reference) "less-bootstrap/mixins.less";

//fonts
@import "../css/font-awesome.min.css";
@import "../css/font-awesome-animation.min.css";
@import "../css/pe-icon-7-stroke.css";
/* @import "../css/elegant-icons.css"; */
/* @import "../css/stroke-gap-icons.css"; */
@import "../css/utility-classes.css";
@import "../css/flaticon.css";
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800|Droid+Serif:400,400i,700,700i);

// Initialize Variables
@import "less-martial-art/variables.less";
@import "less-martial-art/mixins.less";

// Typography
@import "less-martial-art/typography.less";

// Common Styles
@import "less-martial-art/common.less";
@import "less-martial-art/extra.less";
@import "less-martial-art/overlay.less";

// Header
@import "less-martial-art/header.less";

// Nav
@import "less-martial-art/nav.less";

// Content Blocks
@import "less-martial-art/topbar.less";
@import "less-martial-art/inner-header-title.less";
@import "less-martial-art/vertical-nav.less";
@import "less-martial-art/menu-full-page.less";
@import "less-martial-art/boxed-layout.less";
@import "less-martial-art/form.less";
@import "less-martial-art/side-push-panel.less";
@import "less-martial-art/box-hover-effect.less";
@import "less-martial-art/gallery-isotope.less";
@import "less-martial-art/sliders.less";
@import "less-martial-art/home.less";
@import "less-martial-art/about.less";
@import "less-martial-art/causes.less";
@import "less-martial-art/volunteer.less";
@import "less-martial-art/contact.less";
@import "less-martial-art/event.less";
@import "less-martial-art/practice-area.less";
@import "less-martial-art/services.less";
@import "less-martial-art/job.less";
@import "less-martial-art/shop.less";
@import "less-martial-art/blog.less";
@import "less-martial-art/classes-details.less";
@import "less-martial-art/class-time.less";
@import "less-martial-art/trainer.less";
@import "less-martial-art/traning-class.less";
@import "less-martial-art/calculator.less";

// Shortcodes
@import "less-martial-art/shortcodes.less";


// Widgets
@import "less-martial-art/widgets.less";


// Footer
@import "less-martial-art/footer.less";