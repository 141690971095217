/*
 *  class-time.less
 * -----------------------------------------------
*/
.class-items {
    .thumb  {
        position: relative;
        overflow: hidden;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            .transition(all .3s ease 0s);
            opacity: 0;
            top: -200px;
            background: rgba(0,0,0,0.5);
        }
    }
    .viewtime {
        bottom: -43px;
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        opacity: 0;
        .transition(all .3s ease 0s);
    }
    &:hover {
        .thumb:before {
            opacity: 1;
            top: 0;
        }
        .viewtime  {
            bottom: 0;
            opacity: 1;
        }
    }
}
