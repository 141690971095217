/*
 * Section: classes-details.less
 * -----------------------------------------------
*/
.single-class-details {
  border-bottom: 1px solid @gray-lightgray;
  display: inline-block;
}
.classes-schedules .dl-horizontal dt {
	text-align: left;
}